<template>
  <div class="Counselor bg-white">
    <Row class="px-1">
      <i-col span="20" class="align-justify">
        <Button type="default" icon="md-refresh" size="large" class="mr-1"></Button>
        <Button type="default" icon="md-add" size="large" class="mr-1" @click="addCounselor"></Button>
        <Select
          v-model="gender"
          size="large"
          style="width: 5rem;margin-right: 0.5rem;"
          @on-select="selectGender"
        >
          <Option :value="-1">性别</Option>
          <Option :value="1">男</Option>
          <Option :value="0">女</Option>
        </Select>
        <Select
          v-model="titleSelect"
          size="large"
          style="width: 8rem;margin-right: 0.5rem;"
          @on-select="selectZizhi"
        >
          <Option :value="-1">资质</Option>
          <Option v-for="(tItem,tkey) in titleOption" :key="tkey" :value="tkey">{{tItem.label}}</Option>
        </Select>
        <Select
          v-model="waySelect"
          size="large"
          style="width: 10rem;margin-right: 0.5rem;"
          @on-select="selectConsultType"
        >
          <Option :value="-1">咨询方式</Option>
          <Option v-for="(wItem,wkey) in wayOption" :key="wkey" :value="wItem.value">{{wItem.label}}</Option>
        </Select>
        <i-input
          v-model="inputValue"
          size="large"
          style="width: 15rem"
          class="el-inline mr-1"
          placeholder="请输入咨询师姓名进行查询"
        >
          <Button slot="append" icon="md-search" style="width: 2.5rem" @click="HandleSearch"></Button>
        </i-input>
        <Button size="large" class="mr-1">批量{{deOrRe}}</Button>
        <Button size="large">批量导出</Button>
      </i-col>
      <i-col span="4" class="align-right colHeigth">
        <Checkbox @on-change="changeStatus">被移除从业者</Checkbox>
      </i-col>
      <i-col span="24">
        <Table
          border
          stripe
          ref="counselorTable"
          :loading="searching"
          :columns="tColumns"
          :data="tData"
          :height="theight"
          class="mt-1"
        >
          <div slot-scope="{row, index }" slot="action">
            <Button
              type="primary"
              size="small"
              style="margin-right: 0.25rem"
              @click="counselorDetails(row)"
            >详情</Button>
            <Button size="small" @click="remove(index)">{{deOrRe}}</Button>
          </div>
        </Table>
        <Page
          ref="usersPagination"
          :total="recordTotal"
          :page-size="20"
          @on-change="changePage"
          @on-page-size-change="changeSize"
          show-total
          show-sizer
          show-elevator
          class="p-1 align-right"
        />
      </i-col>
    </Row>
  </div>
</template>

<script>
export default {
  name: "Counselor",
  data() {
    return {
      inputValue: "",
      gender: -1,
      consultType: -1,
      titleSelect: -1,
      selectTitle: "",
      searching: true,
      titleOption: [],
      waySelect: -1,
      wayOption: [
        { label: "面对面咨询", value: 1 },
        { label: "视频咨询", value: 2 },
        { label: "语音咨询", value: 3 },
      ],
      selectValue: "counselorName",
      deOrRe: "移除",
      theight: window.innerHeight - 275,
      pageSize: 20,
      recordTotal: 0,
      pageNum: 1,
      tColumns: [
        {
          type: "selection",
          width: 35,
          align: "center",
        },
        {
          type: "index",
          title: "序号",
          width: 45,
          align: "center",
        },
        {
          title: "姓名",
          key: "consultantName",
          minWidth: 50,
        },
        {
          title: "图片",
          minWidth: 80,
          key: "pictrue",
          render: (h, params) => {
            let devicesArr = [];
            let photo = [];
            photo.push(params.row.pictrue);
            devicesArr.push(
              h(
                "viewer",
                {
                  props: {
                    images: photo,
                  },
                },
                [
                  h("img", {
                    attrs: {
                      src: photo[0],
                      style: "width: 60px;height:60px;margin-right:5px",
                    },
                  }),
                ]
              )
            );
            return h("div", devicesArr);
          },
          align: "center",
        },
        {
          title: "性别",
          key: "gender",
          render: (h, params) => {
            return h("span", this.myjs.getGender(params.row.gender));
          },
          minWidth: 20,
        },
        {
          title: "年龄",
          key: "age",
          render: (h, params) => {
            let age = this.myjs.getAge(params.row.birthday, new Date());
            return h("span", age);
          },
          minWidth: 30,
          sortable: true,
        },
        {
          title: "手机号",
          key: "phone",
          minWidth: 100,
        },
        {
          title: "资质",
          key: "consultantTitle",
          minWidth: 100,
        },
        {
          title: "面对面咨询",
          key: "face",
          minWidth: 30,
          render: (h, params) => {
            let face = this.myjs.setConsultType(params.row.face);
            return h("span", face);
          },
        },
        {
          title: "面询价格",
          key: "facePrice",
          minWidth: 50,
        },
        {
          title: "视频咨询",
          key: "video",
          minWidth: 30,
          render: (h, params) => {
            let video = this.myjs.setConsultType(params.row.video);
            return h("span", video);
          },
        },
        {
          title: "视频价格",
          key: "videoPrice",
          minWidth: 50,
        },
        {
          title: "语音咨询",
          key: "voice",
          minWidth: 30,
          render: (h, params) => {
            let voice = this.myjs.setConsultType(params.row.voice);
            return h("span", voice);
          },
        },
        {
          title: "语音价格",
          key: "voicePrice",
          minWidth: 50,
        },
        {
          title: "简介",
          key: "attitude",
          minWidth: 100,
          tooltip: true,
        },
        {
          title: "操作",
          slot: "action",
          width: 102,
        },
      ],
      tData: [],
    };
  },
  mounted() {
    window.addEventListener(
      "resize",
      () => (this.theight = window.innerHeight - 275),
      false
    );
    this.$get("Reserve/GetTitleList").then((res) => {
      console.log(res);
      this.titleOption = res.data.responseList;
      this.getConsultantList();
    });
  },
  methods: {
    counselorDetails(row) {
      let a = JSON.stringify(row);
      sessionStorage.setItem("pageStr", a);
      this.$router.push({ name: "CounselorDetails" });
    },
    addCounselor() {
      let a = JSON.stringify("");
      sessionStorage.setItem("pageStr", a);
      this.$router.push({ name: "CounselorDetails" });
    },
    changeStatus(status) {
      if (status) {
        this.deOrRe = "还原";
      } else {
        this.deOrRe = "移除";
      }
    },
    getConsultantList() {
      this.searching = false;
      this.$get("Reserve/GetConsultantList", {
        gender: this.gender,
        title: this.selectTitle,
        consultType: this.consultType,
        keyword: this.inputValue,
        pageSize: this.pageSize,
        pageNum: this.pageNum,
      }).then((res) => {
        this.tData = res.data.responseList;
        this.recordTotal = res.data.total;
        this.searching = false;
        this.$Message.success({
          content: "加载成功",
          duration: 3,
        });
      });
    },
    selectGender(e) {
      this.gender = e.value;
      this.getConsultantList();
    },
    selectZizhi(e) {
      if (e.value == -1) {
        this.selectTitle = "";
      } else {
        this.selectTitle = e.label;
      }
      this.getConsultantList();
    },
    selectConsultType(e) {
      this.consultType = e.value;
      this.getConsultantList();
    },
    HandleSearch() {
      this.getConsultantList();
    },
    changeSize(e) {
      this.pageSize = e;
      this.getConsultantList();
    },
    changePage(e) {
      this.pageNum = e;
      this.getConsultantList();
    },
  },
};
</script>

<style>
</style>
